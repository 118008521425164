(function($) {
  /* frontend begin */
  $(".js-forms").each(function() {
    $(this).on("submit", function(e) {
      e.preventDefault();

      var el = $(this),
        scope = el.closest(".js-forms");
      if (!el.hasClass("disabled")) {
        el.addClass("disabled");

        scope.validationEngine("attach", {
          validationEventTrigger: "submit",
          promptPosition: "topLeft"
        });

        if (scope.validationEngine("validate")) {
          $.ajax({
            type: "post",
            dataType: "json",
            url: templateJS.ajaxURL,
            data: {
              action: "submit_form",
              form: scope.serialize()
            },
            success: function(r) {
              scope.find(".js-message-area").fadeOut("slow", function() {
                scope
                  .find(".js-message-area")
                  .html(r.message)
                  .fadeIn("slow");
              });
              if (r.code === 200) {
                scope.find(".js-fields").slideUp("slow");
                el.addClass("disabled");
              } else {
                el.removeClass("disabled");
              }
            }
          });
        } else {
          el.removeClass("disabled");
        }
      }
    });
  });
  /* frontend end */

  /* backend begin */
  $(document).on("click", ".remove-item", function(e) {
    e.preventDefault();
    var el = $(this);
    $.ajax({
      type: "get",
      url: ajaxurl,
      dataType: "html",
      data: el.data(),
      success: function() {
        el.closest(".item-wrap").slideUp("slow", function() {
          $(this).remove();
        });
      }
    });
  });

  $(".email-wrap")
    .on("click", ".email-toggle", function(e) {
      e.preventDefault();
      var el = $(this),
        txt = el.text();
      if (txt === "Show") {
        txt = "Hide";
      } else {
        txt = "Show";
      }

      el.text(txt)
        .closest(".email-wrap")
        .find(".hidden")
        .slideToggle("slow");
    })
    .on("click", ".form-status", function(e) {
      e.preventDefault();

      var el = $(this),
        status = el.data("status"),
        id = el.data("id"),
        count = $("#count_" + pagenow)
          .find("span")
          .html();
      switch (status) {
        case "yes":
          status = "no";
          count++;
          break;
        case "no":
          status = "yes";
          count--;
          break;
      }

      $.ajax({
        type: "get",
        url: ajaxurl,
        dataType: "html",
        data: {
          action: "update_response",
          status: status,
          id: id
        },
        success: function() {
          el.removeClass("no yes")
            .html(status)
            .addClass(status)
            .data("status", status);
          if (count === 0) {
            $("#count_" + pagenow).remove();
          } else {
            $("#count_" + pagenow)
              .find("span")
              .html(count);
          }
        }
      });
    })
    .on("click", ".resend", function(e) {
      e.preventDefault();

      var el = $(this);
      el.text("Thinking...");

      $.ajax({
        type: "get",
        url: ajaxurl,
        dataType: "html",
        data: el.data(),
        success: function() {
          el.text("Sent!");
        }
      });
    });

  $(document).on("click", ".checkbox-check-all input", function() {
    $(".checkall").prop("checked", $(this).prop("checked"));
  });

  $(document)
    .on("click", ".add-new-destination,.update-destination", function(e) {
      e.preventDefault();

      var el = $(this),
        scope = el.closest(".item-wrap");
      showLoader();

      el.text("Thinking");

      if (
        el.hasClass("add-new-destination") &&
        !scope.find(".destination-email").val().length
      ) {
        hideLoader();
        $(".updated.error")
          .html(
            "<p>Are you sure about that? Maybe an email? Pretty please?</p>"
          )
          .slideDown("slow");
        el.text("Nope");
        return;
      }

      $.ajax({
        type: "get",
        dataType: "html",
        url: ajaxurl,
        data: {
          action: "save_destination",
          form: scope.find(".destination-form").val(),
          section: scope.find(".destination-section").val(),
          name: scope.find(".destination-name").val(),
          email: scope.find(".destination-email").val(),
          index: scope.find(".destination-index").val()
        },
        success: function(r) {
          hideLoader();

          if (r === "nevermore") {
            scope.slideUp("slow", function() {
              $(this).remove();
            });
          } else if (el.hasClass("add-new-destination")) {
            var tk421 = scope.clone();
            el.text("Another!");
            tk421
              .find(".destination-form")
              .val(scope.find(".destination-form").val());
            tk421
              .find(".destination-section")
              .val(scope.find(".destination-section").val());
            tk421
              .find(".add-new-destination")
              .removeClass("add-new-destination")
              .addClass("update-destination")
              .text("Update");
            tk421
              .find(".removal-area")
              .show()
              .find(".confirm-remove")
              .data("index", r);
            tk421.insertAfter(scope);
          } else {
            el.text("Saved");
          }
        }
      });
    })

    .on("click", ".js-popup-form-submission-open", function(e) {
      e.preventDefault();
      var submissionid = $(this).data("submissionid");

      $.magnificPopup.open({
        items: [
          {
            src: "#meta-form-submission-" + submissionid, // CSS selector of an element on page that should be used as a popup
            type: "inline"
          }
        ]
      });
    });
  /* backend end */
})(jQuery);
