(function ($) {

  if ($('.js-popup').length) {

    var getMagnificItemForEl = function (el) {

      var
        magnificItem = {
          src: el.data('src') || el[0].href,
          title: el.data('title')
        },
        type = el.data('type');

      //a little help
      if (magnificItem.src.match(/vimeo|youtube|maps/i)) {
        type = 'iframe';
      } else if (magnificItem.src.match(/(jpg|gif|png|jpeg)(\?.*)?$/i)) {
        type = 'image';
      }

      switch (type) {
        case 'image':
          magnificItem.type = 'image';
          break;
        case 'inline':
          magnificItem.type = 'inline';
          magnificItem.src = $(magnificItem.src);
          break;
        case 'ajax':
          magnificItem.type = 'ajax';
          break;
        case 'iframe':
        case 'video':
        case 'map':
          magnificItem.type = 'iframe';
          break;
      }

      return magnificItem;
    };

    //magnific popup
    $(document).on('click', '.js-popup', function (e) {
      e.preventDefault();
      var
        magnificItems,
        items,
        isPartOfGallery,
        thisIndex = 0,
        el = $(this),
        galleryName = el.data('gallery');

      isPartOfGallery = !!galleryName;

      if (isPartOfGallery) {
        magnificItems = [];
        items = $('.js-popup').filter(function () {
          return $(this).data('gallery') === galleryName;
        });

        items.each(function (i) {
          if (this === el[0]) {
            thisIndex = i;
          }
          magnificItems.push(getMagnificItemForEl($(this)));
          return true;
        });
      } else {
        magnificItems = getMagnificItemForEl(el);
      }

      $.magnificPopup.open({
        items: magnificItems,
        disableOn: 700,
        // mainClass: 'mfp-zoom-in',
        // removalDelay: 160,
        preloader: true,
        fixedContentPos: true,
        gallery: {enabled: isPartOfGallery}
      }, thisIndex);

    });

  }

}(jQuery));
