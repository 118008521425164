(function($) {

  function DoArchive( scope ) {

    function setup() {

      updatePagination();

      scope.find( '.js-filter-area' ).on( 'click', '.js-bar-item', events.filterClick );
      scope.find( '.js-filter-area' ).on( 'submit', '.js-filter-form', events.filterSubmit );
      scope.find( '.js-filter-area' ).on( 'change', '.js-filter-select', events.filterSelect );

      scope.on( 'click', '.js-filtered-results div', clear.generalClear );

    }

    function updatePagination() {

      var
        paged = parseInt( scope.data('paged') )
        ;

      if ( scope.find('.js-filter-next').length ) {

        scope.find('.js-filter-next,.js-filter-prev').addClass('disabled');

        if ( paged > 1 ) {
          scope.find('.js-filter-prev').removeClass('disabled');
        }

        if ( paged && paged < scope.find('.js-total-count').data('pages') ) {
          scope.find('.js-filter-next').removeClass('disabled');
        }

      }

      if ( scope.find('.js-totals').length ) {
        var
          el = scope.find('.js-totals'),
          total = parseInt( scope.find('.js-total-count').data('total') ) || 0,
          txt = total + ' ' + el.data('multiple')
          ;

        if ( total === 1 ) {
          txt = total + ' ' + el.data('single');
        }

        el.text( txt );
      }

    }

    function refreshLists() {

      showLoader();

      /*
       var
       search = ( scope.find('.filter-search').val() === scope.find('.filter-search').attr('placeholder') ) ? '' : scope.find('.filter-search').val()
       ;
       */

      $.ajax({
        type		: 'get',
        dataType	: 'html',
        url			: templateJS.ajaxURL,
        data		: scope.data(),
        success		: function(r) {
          hideLoader();

          /*
           if ( typeof _gaq !== 'undefined' && search !== null && scope.data( 'gaq' ).length ) {
           _gaq.push(['_trackPageview','/?s=' + search + '&section=' + scope.data( 'gaq' ) ] );
           }
           */

          scope.find('.js-the-response-grid').fadeOut('slow', function() {
            $(this).html(r).fadeIn('slow');

            updatePagination();
            $(document).trigger('updateTemplate');

          });
        }
      });

    }

    var events = {

      filterClick : function( event ) {

        event.preventDefault();
        if ( ! $(this).hasClass('disabled') ) {

          var paged = parseInt( scope.data('paged') );

          // if ( $(this).hasClass('selected') ) {
          //   scope.data( $(this).data('name'), '' );
          //   $(this).removeClass('selected');
          //
          // } else
          if ( $(this).hasClass('js-filter-next') ) {
            scope.data('paged',  paged + 1 );

          } else if ( $(this).hasClass('js-filter-prev') ) {
            scope.data('paged',  paged - 1 );

          } else if ( $(this).data('order') ) {
            $(this).toggleClass('selected').siblings().removeClass('selected');
            scope.find('.js-filter-order').val( scope.find('.js-bar-item.selected').data('order') );

          } else {
            scope.data( $(this).data('name'), $(this).data('value') );
            $(this).addClass('selected').parent().siblings().find('a').removeClass('selected');

          }

          refreshLists( );
        }

      },

      filterSubmit : function( event ) {

        event.preventDefault();
        scope.data('paged', 1);
        scope.data('s', scope.find('.js-filter-search').val() );
        refreshLists( );

      },

      filterSelect : function( event ) {

        event.preventDefault();
        scope.data( $(this).prop('name'), $(this).val() );
        refreshLists( );

      }

    };

    var clear = {

      generalClear : function( event ) {

        event.preventDefault();

        var
          el = $(this),
          reset = el.data('reset').split(',')
          ;

        reset.map( function( item ) {

          switch ( item ) {
            case 'date' :
              scope.data( 'yyyy', '' );
              scope.data( 'mm', '' );

              break;
            case 'search' :
              scope.find( '.js-filter-search' ).val('');
              scope.data( 's', '' );

              break;
            case 'paged' :
              scope.data( 'paged', 1 );

              break;
            case 'select' :
              scope.data( el.data('select'), '' );
              scope.find( '[name="' + el.data('select') + '"]' ).val( scope.find( '[name="' + el.data('select') + '"] option:first' ).val() );
              var span = scope.find( '[name="' + el.data('select') + '"]' ).closest('.selector').find('span');
              span.text( span.data('default') );

              break;
            case 'button' :
              scope.data( el.data('button'), '' );
              scope.find( '.' + el.data('class') ).removeClass('selected');

              break;
          }

        });

        refreshLists( $(this) );

      }

    };

    setup();

  }

  $('.js-scope-wrap').each(function () {
    new DoArchive($(this));
  });

}(jQuery));
